import React, { Component } from 'react';
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import * as memberActions from "../../redux/member/member_actions";
import * as rosterActions from "../../redux/roster/roster_actions";

import Calendar from "../staff-calendar/staff-calendar";
import '../register/register.css'
import './staff-info.css'
import { browserHistory } from 'react-router';
import { Button, Image, Modal } from "react-bootstrap";
import Config from "../../config";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from "moment";

import Header from '../header/header';

class StaffProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idStaff: null,
            memberInfo: null,
            listMember: null,
      updatemakeAdmin: false,
      modalVisible: false,
      rosterList: [],
        };
        this.dataFilter = {
            limit: 1000,
            skip: 0
        };
    }

    componentDidMount() {
        let state = this.props.location.state;
        let id = state && state.id ? state.id : null;
        if (!id) {
            browserHistory.goBack();
            return;
        }

        this.setState({
            idStaff: id
        });

        this._init(id);

    }

    _init(id) {
        this.props.memberActions.getMemberInfo({ id: id }, (err, data) => {
            if (err) {
                Config.alertMess(err);
            }
            else {
                this._getMemberTeamList(data.team.id);
                this.setState({
                    memberInfo: data
                }, () => {
                    let { teamId } = this.props;
                    let dataFilter = this.dataFilter;
                    if (!data.user || !data.user.id) dataFilter.team = teamId;
                    if (data.user && data.user.id) dataFilter.staff = data.user.id;
                    dataFilter.timeFrom = moment().startOf('year').toISOString();
                    dataFilter.timeTo = moment().endOf('year').toISOString();
                    this.props.rosterActions.getListRoster(dataFilter, (err, data) => {
                        if (err) {
                            this.setState({
                                rosterList: []
                            });
                        }
                        else if (data) {
                            this.setState({
                                rosterList: data
                            });
                        }
                    });
                });
            }
        });
    }

    _getMemberTeamList = (id) => {
        this.props.teamActions.getMemberTeamList({ team: id }, (err, data) => {
            if (err) {
                Config.alertMess(err);
            }
            else {
                this.setState({
                    listTeam: data
                })
                // that._goScreenTeamDetai();
            }
        });
    };

    _goRemove = (item) => {
        Config.popup.show('YES_NO', 'Do you want to remove member?', () => { this._removeMember(item) });

    };

    _goAsAdmin = (item) => {
        Config.popup.show('YES_NO', 'Do you want to make this user to admin?', () => { this._makeAsAdmin(item) });
    };

    _goAsStaff = (item) => {
        Config.popup.show('YES_NO', 'Do you want to change from admin to staff?', () => { this._makeAsNormal(item) });
    };

  removeasAdmin = (item) => {
    this.setState({
      updatePlanPayment: !this.props.memberActions.editMember({
        id: item.id,
        role: "STAFF",
      }),
    });
  };
    _removeMember = (item) => {
        this.props.teamActions.removeMember({ id: item.id }, (err, data) => {
            if (err) Config.alertMess(err);
            else {
                // this._getMemberTeamList();
                browserHistory.goBack();
                // Config.alertMess({code: 'WW002'})
            }
        })
    };


  _makeAsNormal = (item) => {
    this.props.memberActions.editMember(
      { id: item.id, role: "STAFF" },
      (err, data) => {
        if (err) Config.alertMess(err);
        else {
          this._init(data.id);
        }
      }
    );
  };

    changeTimeToSecond = (time) => {
        // let a = time.toString().split('.');
        // let second = (parseFloat(time)-parseFloat(a[0]))* 60;
        // second = second < 9 ? '0'+ parseInt(second,10) : parseInt(second,10);
        // a = a[0] + ':' + second;
        // return a;
        let a = time.toString().split('.');
        let second = Math.round(parseFloat('0.' + (a[1] || 0)) * 60);
        second = second <= 9 ? '0' + parseInt(second, 10) : parseInt(second, 10);
        a = a[0] + ':' + second;
        return a;
    };

  goLink = (item, opacity) => {
    if (!item || opacity !== 1) return;
    let { memberInfo } = this.state;
    let { teamId } = this.props;
    if (item.name === "MESSAGES") {
      browserHistory.push(
      "/messages?type=user&uid=" + memberInfo.user.id + "&tid=" + teamId
      );
    } else if (item.name === "TASKS") {
      browserHistory.push(
        "/tasks?user=" + memberInfo.user.id + "&tid=" + teamId
      );
    } else if (item.name === "CALENDAR") {
      browserHistory.push(
        "/calendar"
      );
    } else if (item.name === "ROSTER") {
      browserHistory.push(
        "/roster?user=" + memberInfo.user.id + "&tid=" + teamId
      );
    }
  };

  makeAdmin = () => {
    this.setState({ isUpdateMakeAdminConfirmed: true });
  };

  _ConfirmAdmin = (item) => {
    this._makeAsAdmin(item);
  };

  _makeAsAdmin = (item) => {
    this.props.memberActions.editMember(
      { id: item.id, role: "ADMIN" },
      (err, data) => {
        if (err) {
        } else {
           console.log("Member is now an admin:", data);
           this.setState({ isUpdateMakeAdminConfirmed: false });
           this._init(item.id);
        }
      }
    );
  };

  removeAdmin = () => {
    this.setState({ isUpdateRemoveAdminConfirmed: true });
  };

  _ConfirmRemoveAdmin = (item) => {
    this._removeAsAdmin(item);
  };

  _removeAsAdmin = (item) => {
    this.props.memberActions.editMember(
      { id: item.id,  role: "STAFF", },
      (err, data) => {
        if (err) {
        } else {
          this.setState({ isUpdateRemoveAdminConfirmed: false });
          this._init(item.id);
        }
      }
    );
  };

  removeMember = () => {
    this.setState({ isUpdateRemoveMemberConfirmed: true });
  };

  _ConfirmRemove = (item) => {
    this._removeMembers(item);
  };

  _removeMembers = (item) => {
    this.props.teamActions.removeMember({ id: item.id }, (err, data) => {
      if (err) {
      } else {
        browserHistory.goBack();
      }
    });
  };

  _handleClose = () => {
    this.setState({ isUpdateRemoveMemberConfirmed: false });
    this.setState({ isUpdateMakeAdminConfirmed: false });
    this.setState({ isUpdateRemoveAdminConfirmed: false });
  };

  _onLogout = () => {
    this.props.mainActions.checkOut((err, data) => {
        window.location.reload();
    });
};

    render() {
        let { memberList } = this.props;
        let { memberInfo } = this.state;
        let category = [

            {
                name: 'ROSTER',
                title: 'Working Saturday :(',
                image: require('../../assets/images/home/img-roster.png'),
                link: ''
            },
            {
                name: 'TASKS',
                title: 'But I hate that!',
                image: require('../../assets/images/home/img-task.png'),
                link: ''
            },
            {
                name: 'MESSAGES',
                title: 'Say hello!',
                image: require('../../assets/images/home/img-meassage.png'),
                link: ''
            },
            {
                name: 'CALENDAR',
                title: 'Deadlines, deadlines!',
                image: require('../../assets/images/home/img-calendar.png'),
                link: ''
            },
        ];
        // if(!this.state.memberInfo || !memberInfo) return null;

        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;

        let isAdmin = false;
        let listAdmin = false;
        if (this.state.listTeam) {
            isAdmin = memberList.filter(i => ((i.role === 'OWNER' || i.role === 'ADMIN') && i.user.id === checkLogin.user.id));
        }
        if (this.state.listTeam) {
            listAdmin = memberList.filter(i => i.role === 'ADMIN');
        }
        let isRemove = false;
        let itMe = false;
        if (memberInfo && memberInfo.user && checkLogin.user.id === memberInfo.user.id) {
            itMe = true;
        }
        if ((memberInfo && memberInfo.user && checkLogin.user.id !== memberInfo.user.id) &&  // info is not me
            isAdmin && isAdmin.length > 0 && //user is admin
            memberInfo.role !== 'OWNER' //info not owner
        ) {
            isRemove = true;
        }
        let isStaff = this.props.location.state.staff;

        let dataRoster = this.state.rosterList.slice(0);
        dataRoster = dataRoster.filter(i => { return ((moment(i.time).format('DD MM YYYY') === moment().format('DD MM YYYY') || moment(i.time).valueOf() > moment().valueOf()) && i.staff.id === memberInfo.user.id) });
        dataRoster = dataRoster.sort((i, j) => { return moment(i.time).valueOf() - moment(j.time).valueOf() });


        let formatDate = {
            sameDay: '[Today]',
            nextDay: 'DD MMM',
            nextWeek: 'DD MMM',
            lastDay: 'DD MMM',
            lastWeek: 'DD MMM',
            sameElse: 'DD MMM'
        };

        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;

        return (
            <div className="full_w full_h staff_container" style={{ position: 'relative' }}>
               <Header isSearch={false} type={1} login={login} onLogout={this._onLogout}/>

                <div className="register staff-info my-team row scroll" style={{marginTop:-30}}>
                    {isStaff &&
                        <div className="title-staff-info">
                            <div className="display_row align-center go-back"
                                onClick={() => {
                                    browserHistory.goBack()
                                }}>
                                <img src={require('../../assets/images/roster/icon-back-def.png')} alt={""} />Back to Staff Hours
                            </div>

                            <div className="txt-condensed-extra-bold" style={{
                                fontSize: 24,
                                lineHeight: '30px',
                                marginTop: 28,
                                width: '100%',
                                textAlign: 'center'
                            }}>STAFF HOURS
                            </div>
                        </div>
                    }
                    {/*<div className="full_h_1 ">*/}
                    <div className="display_row" style={{ position: 'relative' ,paddingTop:"30px"}}>
                        <Image className="shadow staff-avatar" src={memberInfo && memberInfo.user && memberInfo.user.avatar ? memberInfo.user.avatar : Config.avaDef} />
                        <div className="display_col justify-start">
                            <span className="txt-name">{memberInfo && memberInfo.name ? memberInfo.name : '---'}</span>
                            <span className="txt-note">{(memberInfo && ((memberInfo.role === 'OWNER') || (memberInfo.team.owner === memberInfo.user.id))) ? "" :(memberInfo && memberInfo.title 
                             ? (memberInfo.title.length > 15   ? memberInfo.title.substring(0, 15) + "..."   : memberInfo.title)  : '---') }</span>
                            <span className="txt-note">{memberInfo && memberInfo.team ? memberInfo.team.name : '---'}</span>
                            <span className="txt-work">
                            {console.log("dataroster..",dataRoster)}
                            {(dataRoster && dataRoster.length > 0) ? 
                              (dataRoster[0].holiday === "APPROVED" ? "HOLIDAY" :
                                  (dataRoster[0].time ? 
                                      `${moment(dataRoster[0].time).calendar(null, formatDate)} ${this.changeTimeToSecond(dataRoster[0].timeStart)}-${this.changeTimeToSecond(dataRoster[0].timeEnd)}` 
                                      : "OFF")
                              ) 
                              : "OFF"
                            }
                            </span>
                        </div>
                        <div className="display_col valign-top" style={{ position: 'absolute', right: 57 }}>
                            {isRemove && !isStaff && memberInfo &&
                                <Button className="menu-left-action btn-linear-orange shadow_popup btn-linear-invite"
                                    style={{ position: 'initial', marginBottom: 20 }}
                                    onClick={() => this.removeMember(memberInfo)}>REMOVE</Button>
                            }
                            {isRemove && !isStaff && memberInfo && memberInfo.role !== 'ADMIN' && listAdmin.length < 3 &&
                                <Button className="menu-left-action btn-linear-orange btn-linear shadow_popup btn-linear-invite"
                                    style={{ position: 'initial' }}
                                    onClick={() => this.makeAdmin(memberInfo)}>MAKE MANAGER</Button>
                            }
                            {isRemove && !isStaff && memberInfo && memberInfo.role !== 'STAFF' &&
                                <Button className="menu-left-action btn-linear-orange btn-linear shadow_popup btn-linear-invite"
                                    style={{ position: 'initial' }}
                                    onClick={() => this.removeAdmin(memberInfo)}>REMOVE AS MANAGER</Button>
                            }
                        </div>
                    </div>
                    <div className="display_row calendar-view">
                        {category && category.map((key, idx) => {
                         
                            let opacity = key.name === 'MESSAGES' && itMe ? 0.2 : 1;
                            return (
                                <div className="col-lg-3 calendar-item shadow"
                                    style={{ opacity: opacity }}
                                    key={"div-item" + idx}
                                    onClick={() => this.goLink(key, opacity)}>
                                    <Image src={key.image} />
                                    <span className="txt-work txt-calendar-work tool-name">{key.name}</span>
                                    <span className="txt-calendar-note tool-title" id={`tool-title_${idx}`}>{key.title}</span>
                                </div>
                            )
                        })}
                    </div>
                    <Calendar datRoster={this.state.rosterList} idStaff={this.state.idStaff} memberInfo={memberInfo} />
                    {/*</div>*/}
          <Modal
            show={this.state.isUpdateMakeAdminConfirmed}
            onHide={this.closeModal}
            id="popup-change-plan"
            className={"root-popup-change-plan remove-team-member"}
            style={{ width: 'fit-content', maxWidth: "100%", margin: "auto" }}
          >
           
            <Modal.Body>
              <Image
                className="logo remove-member-logo"
                src={require("../../assets/images/register/icon-member.png")}
              />
              <div className="title">Promote To Manager</div>
              <div className="title1 remove-admin">Do you wish to promote this user to an manager role?</div>
              <div className="button_display_row" style={{ padding: "10px 0" }}>
               
                <Button
                  className="login_btn_login btn-linear-orange shadow_popup"
                  style={{ maxHeight: 43,margin: "0px 43px 0px 0px" , borderRadius:"25px" }}
                  onClick={this._handleClose}
                >
                  CANCEL
                </Button>
                <Button
                  className="login_btn_login btn-linear shadow_popup"
                  style={{ maxHeight: 43, margin: "0 10px" , borderRadius:"25px"}}
                  onClick={() => this._ConfirmAdmin(memberInfo)}
                >
                  CONFIRM
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={this.state.isUpdateRemoveAdminConfirmed}
            onHide={this.closeModal}
            id="popup-change-plan"
            className={"root-popup-change-plan remove-team-member"}
            style={{ width: 'fit-content', maxWidth: "100%", margin: "auto" }}
          >
            
            <Modal.Body>
              <Image
                className="logo remove-member-logo"
                src={require("../../assets/images/register/icon-member.png")}
              />
              <div className="title">Remove As Manager</div>
              <div className="title1 remove-admin">Do you want to remove this user from the manager?</div>
              <div className="button_display_row" style={{ padding: "10px 0" }}>
                
                <Button
                  className="login_btn_login btn-linear-orange shadow_popup"
                  style={{ maxHeight: 43, margin: "0px 43px 0px 0px" , borderRadius:"25px" }}
                  onClick={this._handleClose}
                >
                  CANCEL
                </Button>
                <Button
                  className="login_btn_login btn-linear shadow_popup"
                  style={{ maxHeight: 43, margin: "0 10px",borderRadius:"25px" }}
                  onClick={() => this._ConfirmRemoveAdmin(memberInfo)}
                >
                  CONFIRM
                </Button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.isUpdateRemoveMemberConfirmed}
            onHide={this.closeModal}
            id="popup-change-plan"
            className={"root-popup-change-plan remove-team-member"}
            style={{ width: 'fit-content', maxWidth: "100%", margin: "auto" }}
          >
            
            <Modal.Body>
              <Image
                className="logo remove-member-logo"
                src={require("../../assets/images/register/icon-member.png")}
              />
              <div className="title">Remove Member</div>
              <div className="title1 remove-admin">Do you want to remove this member from the team?</div>
              <div className="button_display_row" style={{ padding: "10px 0" }}>
                
                <Button
                  className="login_btn_login btn-linear-orange shadow_popup"
                  style={{ maxHeight: 50,margin: "0px 43px 0px 0px" ,borderRadius:"25px" }}
                  onClick={this._handleClose}
                >
                  CANCEL
                </Button>
                <Button
                  className="login_btn_login btn-linear shadow_popup"
                  style={{ maxHeight: 50, margin: "0 10px",borderRadius:"25px" }}
                  onClick={() => this._ConfirmRemove(memberInfo)}
                >
                  CONFIRM
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
    }
}
export default connect(state => ({
    checkLogin: state.main.checkLogin,
    teamList: state.team.teamList,
    memberList: state.team.memberList,
    memberInfo: state.member.memberInfo,
    teamId: state.team.teamId
}),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        memberActions: bindActionCreators(memberActions, dispatch),
        rosterActions: bindActionCreators(rosterActions, dispatch)
    })
)(StaffProfile);
